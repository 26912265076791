import { ENV_PROD } from 'environments/i-prod';
import { IEnvConfig } from 'interfaces/i-env-config';

export const ENV_BETA: IEnvConfig = {
  ...ENV_PROD,
  enable: {
    ...ENV_PROD.enable,
    auto_beta: true,
  },
  // enable console.debug messages for staging without affecting prod
  debug_messages: true,
  region_redirect: false,
  identifier: 'beta',
  self_url: 'https://beta.trajektsports.app',
  server_url: 'https://beta.trajektsports.app/node',
  ws_url: 'wss://beta.trajektsports.app/wss',
};
